@.basketHandler = ->
    $(document).ready(->
        notesProductHandler()
        basketProductHandler()
        resizeTextarea()
        showFullScreenImage()
        clearSlide()
    )

    # register basket event
    $(document).on('basketChanged', ->
        count = getCookie('basket_count') || 0
        if (count > 0)
            $('.jsModalSlideBasket').show()
        else
            $('.jsModalSlideBasket').hide()
        $('#basketCount').text(count)
        $('#basketQuoteCount').text(count)
        $('#basketCountMenu').text(count)
        $('#basketCountMenuUser').text(count)
    )

#    # show full screen image
    showFullScreenImage = ->
    clearSlide = ->
        $('.jsModalSlideAddedProductBox').removeClass('active')
        $('.jsBasketQuoteDesc').each(->
            $(this).slideUp()
        )
        $('.jsModalSlide').find('.jsBasketQuoteArrow').each(->
            $(this).css('transform', 'rotate(180deg)')
            $(this).removeClass('active')
        )

    notesProductHandler = ->
        # show notes
        basketAddNotes = $('.jsBasketAddNotes')
        basketAddNotes.each(() ->
            if $(this).text()
                $('.jsBasketNotes').autosize()
            $(this).on('click', ->
                $(this).css('visibility', 'hidden')
                $(this).parents('.jsBasketQuoteItem').find('.jsBasketNotes').slideDown().focus()
                $(this).parents('.jsBasketQuoteItem').find('.jsBasketSaveNotes').show()
            )
        )
        basketAddNotes.on("propertychange input", ->
            if (this.value.length > this.maxlength)
                this.value = this.value.substring(0, this.maxlength)
        )

        # edit notes
        editNotesBtn = $('.jsBasketEditNotes')

        editNotesBtn.each(() ->
            $(this).on('click', (e)->
                e.stopPropagation()
                e.preventDefault()
                thisBtn = $(this)
                objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
                objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
                objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
                objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
                notes = $(this).siblings('.jsBasketNotes').val()
                basket_data = {
                    'basket_action': 'notes'
                    'object_type': objectType
                    'object_id': objectId
                    'notes': notes
                }
                $.ajax({
                    type: 'POST'
                    url: '/myspacestor/quotebasket/change/'
                    data: basket_data
                    success: (data) ->
                        if (data['success'] == true)
                            thisBtn.siblings('.jsBasketNotes').removeAttr('readonly').attr('autofocus', 'true').css('border-color', '#D6D2D2').focus()
                            thisBtn.siblings('.jsBasketSaveNotes').show()
                            thisBtn.siblings('.jsBasketDeleteNotes').hide()
                            thisBtn.hide()
                    error: (data) ->
                        console.log(data)
                    headers: {
                        'X-CSRFToken': getCookie('csrftoken')
                    }
                })
            )
        )

        # save notes
        saveNotesBtn = $('.jsBasketSaveNotes')

        saveNotesBtn.each(() ->
            $(this).on('click', (e)->
                thisBtn = $(this)
                e.stopPropagation()
                e.preventDefault()
                objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
                objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
                notes = $(this).siblings('.jsBasketNotes').val()
                basket_data = {
                    'basket_action': 'notes'
                    'object_type': objectType
                    'object_id': objectId
                    'notes': notes
                }
                $.ajax({
                    type: 'POST'
                    url: '/myspacestor/quotebasket/change/'
                    data: basket_data
                    success: (data) ->
                        if (data['success'] == true)
                            thisBtn.siblings('.jsBasketNotes').removeAttr('autofocus').attr('readonly', 'true').css('border-color', 'transparent')
                            thisBtn.siblings('.jsBasketEditNotes').show()
                            thisBtn.siblings('.jsBasketDeleteNotes').show()
                            thisBtn.hide()
                    error: (data) ->
                        console.log(data)
                    headers: {
                        'X-CSRFToken': getCookie('csrftoken')
                    }
                })
            )

            # view of textarea when it has value
            if $(this).siblings('.jsBasketNotes').val()
                $(this).parents('.jsBasketQuoteItem').find('.jsBasketAddNotes').trigger('click')
                $(this).siblings('.jsBasketNotes').removeAttr('autofocus').attr('readonly', 'true').css('border-color', 'transparent')
                $(this).siblings('.jsBasketEditNotes').show()
                $(this).siblings('.jsBasketDeleteNotes').show()
                $(this).hide()
        )

        # delete notes
        deleteNotesBtn = $('.jsBasketDeleteNotes')

        deleteNotesBtn.each(() ->
            $(this).on('click', (e)->
                e.stopPropagation()
                e.preventDefault()
                thisBtn = $(this)
                objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
                objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
                basket_data = {
                    'basket_action': 'remove_notes',
                    'object_type': objectType,
                    'object_id': objectId,
                }

                $.ajax({
                    type: 'POST'
                    url: '/myspacestor/quotebasket/change/'
                    data: basket_data
                    success: (data) ->
                        if (data['success'] == true)
                            thisBtn.siblings('.jsBasketNotes').val('').removeAttr('readonly autofocus').css({
                                'border-color': '#D6D2D2',
                                'height': '37px'
                            }).slideUp()
                            thisBtn.siblings('.jsBasketEditNotes').hide()
                            thisBtn.parents('.jsBasketQuoteItem').find('.jsBasketAddNotes').css('visibility', 'visible')
                            thisBtn.hide()
                    error: (data) ->
                        console.log(data)
                    headers: {
                        'X-CSRFToken': getCookie('csrftoken')
                    }
                })
            )
        )

    basketProductHandler = ->
        $(document).trigger('basketChanged')

        # add qty
        addQtyBtn = $('.jsSignAdd')

        addQtyBtn.on('click', (e) ->
            e.preventDefault()
            objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
            objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
            valField = $(this).siblings('.jsLandingProductQuantityValue')
            basket_data = {
                'basket_action': 'quantity',
                'object_type': objectType,
                'object_id': objectId,
                'quantity': Number($(this).siblings('.jsLandingProductQuantityValue').val()) + 1,
            }

            $.ajax({
                type: 'POST'
                url: '/myspacestor/quotebasket/change/'
                data: basket_data
                success: (data) ->
                    if (data['success'] == true)
                        valField.val(data['quantity'])
                error: (data) ->
                    console.log(data)
                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )

        # remove qty
        removeQtyBtn = $('.jsSignRemove')

        removeQtyBtn.on('click', (e) ->
            if $(this).siblings('.jsLandingProductQuantityValue').val() > 0
                e.preventDefault()
                objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
                objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
                valField = $(this).siblings('.jsLandingProductQuantityValue')
                basket_data = {
                    'basket_action': 'quantity',
                    'object_type': objectType,
                    'object_id': objectId,
                    'quantity': valField.val() - 1,
                }

                $.ajax({
                    type: 'POST'
                    url: '/myspacestor/quotebasket/change/'
                    data: basket_data
                    success: (data) ->
                        if (data['success'] == true)
                            valField.val(data['quantity'])
                    error: (data) ->
                        console.log(data)
                    headers: {
                        'X-CSRFToken': getCookie('csrftoken')
                    }
                })
            else
                return
        )

        # delete product
        closeBtn = $('.jsRemoveProductFromBasket')
        resetElements = ['.jsLandingProductQuantityValue', '.jsBasketNotes']

        closeBtn.on('click', (e) ->
            actionBtn = $(this)
            e.preventDefault()
            objectType = $(this).parents('.jsBasketQuoteItem').attr('data-object-type')
            objectId = $(this).parents('.jsBasketQuoteItem').attr('data-object-id')
            basket_data = {
                'basket_action': 'remove',
                'object_type': objectType,
                'object_id': objectId,
            }
            $.ajax({
                type: 'POST'
                url: '/myspacestor/quotebasket/change/'
                data: basket_data
                success: (data) ->
                    if (data['success'] == true)
                        $(document).trigger('basketChanged')
                        actionBtn.parents('.jsBasketQuoteItem').remove()
                        actionBtn.parents('.jsBasketQuoteItem').find(resetElements).val('')
                error: (data) ->
                    console.log(data)
                headers: {
                    'X-CSRFToken': getCookie('csrftoken')
                }
            })
        )

    resizeTextarea = ->
        $('.jsBasketNotes').each(() ->
            $(this).on('keydown', ->
                $(this).autosize({append: false})
            )
        )
